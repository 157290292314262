import React from "react";
import { NotifyMe } from "./NotifyMe";
import { SocialIcons } from "./SocialIcons";
import Logo from "../assets/bleeckergames.svg";
import "./Home.scss";

export const Home = () => {
  return (
    <div className="container">
      <img className="logo" src={Logo} alt="BleeckerGamesLogo" />
      <h1 className="title">Welcome to Bleecker Games!</h1>
      <h2 className="description">
        Our website is currently under construction, but we're working hard to
        create an exciting and engaging platform for gaming enthusiasts. Stay
        tuned for updates on our progress as we continue to develop and refine
        our site. In the meantime, you can follow us on social media to stay up
        to date with the latest news and sneak peeks of what's to come.
      </h2>
      <div className="social-icons">
        <SocialIcons />
      </div>
      <NotifyMe />
      <h3 className="description">
        Thank you for your patience and we look forward to sharing our passion
        for gaming with you soon!"
      </h3>
      <div className="cubes">
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
      </div>
    </div>
  );
};
