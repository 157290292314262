import React, { useState } from "react";
import { useFormspark } from "@formspark/use-formspark";
import "./NotifyMe.scss";

const FORMSPARK_FORM_ID = "Vtx4dykT";

export const NotifyMe = () => {
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const [message, setMessage] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit({ message });
    alert("Form submitted");
    console.log("Form submitted");
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="input-container">
        <div className="container__item">
          <div className="form">
            <input
              type="email"
              className="form__field"
              placeholder="Your E-Mail Address"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
              required
            />
            <button
              type="submit"
              disabled={submitting}
              className="btn btn--primary btn--inside uppercase"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
