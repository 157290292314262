import "./TermsOfUse.scss"

export const TermsOfUse = () => {
  return (
    <div id="isPasted" data-custom-class="body" className="terms-of-us-container">
      <div align="flex-start">
        <div align="flex-start">
          <div align="flex-start">
            <div align="flex-start">
              <div align="flex-start">
                <div align="flex-start" data-custom-class="title">
                  <strong>END USER LICENSE AGREEMENT</strong>
                </div>
              </div>
            </div>
            <div align="flex-start">&nbsp;</div>
            <div align="flex-start">
              <strong>
                <span data-custom-class="body_text">Last updated&nbsp;</span>
              </strong>
              <strong>
                <span data-custom-class="body_text">March 01, 2023</span>
              </strong>
            </div>
            <div align="flex-start">&nbsp;</div>
            <div align="flex-start">&nbsp;</div>
          </div>
        </div>
        <div align="flex-start">&nbsp;</div>
        <div align="flex-start">
          <div data-custom-class="body_text">
            OneNotEqualOne is licensed to You (End-User) by Bleecker Games,
            located and registered at 3920 Greenpoint Ave,&nbsp;Queens, New York
            11104, United States&nbsp;("<strong>Licensor</strong>"), for use
            only under the terms of this License Agreement.
          </div>
        </div>
        <div align="flex-start">&nbsp;</div>
        <div align="flex-start">
          <div data-custom-class="body_text">
            By downloading the Licensed Application from Apple's software
            distribution platform ("App Store") and Google's software
            distribution platform ("Play Store"), and any update thereto (as
            permitted by this License Agreement), You indicate that You agree to
            be bound by all of the terms and conditions of this License
            Agreement, and that You accept this License Agreement. App Store and
            Play Store are referred to in this License Agreement as "
            <strong>Services</strong>."
          </div>
        </div>
        <div align="flex-start">&nbsp;</div>
        <div align="flex-start">
          <div data-custom-class="body_text">
            The parties of this License Agreement acknowledge that the Services
            are not a Party to this License Agreement and are not bound by any
            provisions or obligations with regard to the Licensed Application,
            such as warranty, liability, maintenance and support thereof.
            Bleecker Games, not the Services, is solely responsible for the
            Licensed Application and the content thereof.
          </div>
        </div>
        <div align="flex-start">&nbsp;</div>
        <div align="flex-start">
          <div data-custom-class="body_text">
            This License Agreement may not provide for usage rules for the
            Licensed Application that are in conflict with the latest&nbsp;
            <a
              href="https://www.apple.com/legal/internet-services/itunes/us/terms.html"
              target="_blank"
              rel="noopener noreferrer"
              data-custom-class="link"
            >
              Apple Media Services Terms and Conditions
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://play.google.com/intl/en_US/about/play-terms/"
              target="_blank"
              rel="noopener noreferrer"
              data-custom-class="link"
            >
              Google Play Terms of Service
            </a>
            &nbsp;("<strong>Usage Rules</strong>").&nbsp;Bleecker Games
            acknowledges that it had the opportunity to review the Usage Rules
            and this License Agreement is not conflicting with them.
          </div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">
            OneNotEqualOne&nbsp;when purchased or downloaded through the
            Services, is licensed to You for use only under the terms of this
            License Agreement. The Licensor reserves all rights not expressly
            granted to You. OneNotEqualOne is to be used on devices that operate
            with Apple's operating systems ("iOS" and "Mac OS") or Google's
            operating system ("Android").
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div data-custom-class="heading_1">
            <strong>TABLE OF CONTENTS</strong>
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            <a
              href="https://wordtohtml.net/#application"
              data-custom-class="link"
            >
              1. THE APPLICATION
            </a>
          </div>
          <div data-custom-class="body_text">
            <a href="https://wordtohtml.net/#scope" data-custom-class="link">
              2. SCOPE OF LICENSE
            </a>
          </div>
          <div data-custom-class="body_text">
            <a
              href="https://wordtohtml.net/#requirements"
              data-custom-class="link"
            >
              3. TECHNICAL REQUIREMENTS
            </a>
          </div>
          <div data-custom-class="body_text">
            <a href="https://wordtohtml.net/#support" data-custom-class="link">
              4. MAINTENANCE AND SUPPORT
            </a>
          </div>
          <div data-custom-class="body_text">
            <a href="https://wordtohtml.net/#datause" data-custom-class="link">
              5. USE OF DATA
            </a>
          </div>
          <div data-custom-class="body_text">
            <a href="https://wordtohtml.net/#ugc" data-custom-class="link">
              6. USER-GENERATED CONTRIBUTIONS
            </a>
          </div>
          <div data-custom-class="body_text">
            <a
              href="https://wordtohtml.net/#contribution"
              data-custom-class="link"
            >
              7. CONTRIBUTION LICENSE
            </a>
          </div>
          <div data-custom-class="body_text">
            <a
              href="https://wordtohtml.net/#liability"
              data-custom-class="link"
            >
              8. LIABILITY
            </a>
          </div>
          <div data-custom-class="body_text">
            <a href="https://wordtohtml.net/#warranty" data-custom-class="link">
              9. WARRANTY
            </a>
          </div>
          <div data-custom-class="body_text">
            <a
              href="https://wordtohtml.net/#productclaims"
              data-custom-class="link"
            >
              10. PRODUCT CLAIMS
            </a>
          </div>
          <div data-custom-class="body_text">
            <a
              href="https://wordtohtml.net/#compliance"
              data-custom-class="link"
            >
              11. LEGAL COMPLIANCE
            </a>
          </div>
          <div data-custom-class="body_text">
            <a href="https://wordtohtml.net/#contact" data-custom-class="link">
              12. CONTACT INFORMATION
            </a>
          </div>
          <div data-custom-class="body_text">
            <a
              href="https://wordtohtml.net/#termination"
              data-custom-class="link"
            >
              13. TERMINATION
            </a>
          </div>
          <div data-custom-class="body_text">
            <a
              href="https://wordtohtml.net/#thirdparty"
              data-custom-class="link"
            >
              14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
            </a>
          </div>
          <div data-custom-class="body_text">
            <a href="https://wordtohtml.net/#ipr" data-custom-class="link">
              15. INTELLECTUAL PROPERTY RIGHTS
            </a>
          </div>
          <div data-custom-class="body_text">
            <a href="https://wordtohtml.net/#law" data-custom-class="link">
              16. APPLICABLE LAW
            </a>
          </div>
          <div data-custom-class="body_text">
            <a href="https://wordtohtml.net/#misc" data-custom-class="link">
              17. MISCELLANEOUS
            </a>
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>
        <div align="flex-start">
          <div id="application" data-custom-class="heading_1">
            <strong>
              <strong>1.</strong>
              <strong>&nbsp;THE APPLICATION</strong>
            </strong>
          </div>
        </div>
        <div align="flex-start">&nbsp;</div>
        <div align="flex-start">
          <div data-custom-class="body_text">
            OneNotEqualOne&nbsp;("<strong>Licensed Application</strong>") is a
            piece of software created to The purpose of the application is to
            provide users with a fun and engaging mathematical game that
            challenges their addition and subtraction skills. The application
            generates random mathematical expressions with a comparison
            operator, and users must evaluate whether the expression is true or
            false. The game has multiple levels, and users can earn points for
            each correct answer. The application is intended to provide a source
            of entertainment and education for users of all ages who are
            interested in improving their math skills. &mdash; and customized
            for iOS and Android mobile devices ("<strong>Devices</strong>"). It
            is used to The main use of the application is to provide users with
            a fun and interactive way to improve their math skills. The
            application is a mobile game that generates random mathematical
            expressions with a comparison operator, and users must evaluate
            whether the expression is true or false. The game is designed to be
            both educational and entertaining, providing users with a way to
            practice their addition and subtraction skills in a fun and engaging
            way. The main purpose of the application is to help users improve
            their math abilities and to promote learning through gameplay..
          </div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">
            The Licensed Application is not tailored to comply with
            industry-specific regulations (Health Insurance Portability and
            Accountability Act (HIPAA), Federal Information Security Management
            Act (FISMA), etc.), so if your interactions would be subjected to
            such laws, you may not use this Licensed Application. You may not
            use the Licensed Application in a way that would violate the
            Gramm-Leach-Bliley Act (GLBA).
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div id="scope" data-custom-class="heading_1">
            <strong>
              <strong>
                <strong>2. SCOPE OF LICENSE</strong>
              </strong>
            </strong>
          </div>
          <div>&nbsp;</div>
        </div>
        <div align="flex-start">&nbsp;</div>
        <div align="flex-start">
          <div data-custom-class="body_text">
            2.1 &nbsp;You are given a non-transferable, non-exclusive,
            non-sublicensable license to install and use the Licensed
            Application on any Devices that You (End-User) own or control and as
            permitted by the Usage Rules, with the exception that such Licensed
            Application may be accessed and used by other accounts associated
            with You (End-User, The Purchaser) via Family Sharing or volume
            purchasing.
          </div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">
            2.2 &nbsp;This license will also govern any updates of the Licensed
            Application provided by Licensor that replace, repair, and/or
            supplement the first Licensed Application, unless a separate license
            is provided for such update, in which case the terms of that new
            license will govern.
          </div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">
            2.3 &nbsp;You may not share or make the Licensed Application
            available to third parties (unless to the degree allowed by the
            Usage Rules, and with Bleecker Games's prior written consent), sell,
            rent, lend, lease or otherwise redistribute the Licensed
            Application.
          </div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">
            2.4 &nbsp;You may not reverse engineer, translate, disassemble,
            integrate, decompile, remove, modify, combine, create derivative
            works or updates of, adapt, or attempt to derive the source code of
            the Licensed Application, or any part thereof (except with Bleecker
            Games's prior written consent).
          </div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">
            2.5 &nbsp;You may not copy (excluding when expressly authorized by
            this license and the Usage Rules) or alter the Licensed Application
            or portions thereof. You may create and store copies only on devices
            that You own or control for backup keeping under the terms of this
            license, the Usage Rules, and any other terms and conditions that
            apply to the device or software used. You may not remove any
            intellectual property notices. You acknowledge that no unauthorized
            third parties may gain access to these copies at any time. If you
            sell your Devices to a third party, you must remove the Licensed
            Application from the Devices before doing so.
          </div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">
            2.6 &nbsp;Violations of the obligations mentioned above, as well as
            the attempt of such infringement, may be subject to prosecution and
            damages.
          </div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">
            2.7 &nbsp;Licensor reserves the right to modify the terms and
            conditions of licensing.
          </div>
          <div data-custom-class="body_text">&nbsp;</div>
          <div data-custom-class="body_text">
            2.8 &nbsp;Nothing in this license should be interpreted to restrict
            third-party terms. When using the Licensed Application, You must
            ensure that You comply with applicable third-party terms and
            conditions.
          </div>
        </div>
        <div align="flex-start">&nbsp;</div>
        <div align="flex-start">
          <div>&nbsp;</div>
          <div id="requirements" data-custom-class="heading_1">
            <strong>
              <strong>
                <strong>
                  <strong>3. TECHNICAL REQUIREMENTS</strong>
                </strong>
              </strong>
            </strong>
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            3.1 &nbsp;The Licensed Application requires a firmware version
            16.0.2 or higher. Licensor recommends using the latest version of
            the firmware.
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            3.2 &nbsp;Licensor attempts to keep the Licensed Application updated
            so that it complies with modified/new versions of the firmware and
            new hardware. You are not granted rights to claim such an update.
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            3.3 &nbsp;You acknowledge that it is Your responsibility to confirm
            and determine that the app end-user device on which You intend to
            use the Licensed Application satisfies the technical specifications
            mentioned above.
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            3.4 &nbsp;Licensor reserves the right to modify the technical
            specifications as it sees appropriate at any time.
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div id="support" data-custom-class="heading_1">
            <strong>
              <strong>
                <strong>
                  <strong>4. MAINTENANCE AND SUPPORT</strong>
                </strong>
              </strong>
            </strong>
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            4.1 &nbsp;The Licensor is solely responsible for providing any
            maintenance and support services for this Licensed Application. You
            can reach the Licensor at the email address listed in the App Store
            or Play Store Overview for this Licensed Application.
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            4.2&nbsp;&nbsp;Bleecker Games&nbsp;and the End-User acknowledge that
            the Services have no obligation whatsoever to furnish any
            maintenance and support services with respect to the Licensed
            Application.
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div id="datause" data-custom-class="heading_1">
            <strong>
              <strong>
                <strong>
                  <strong>5. USE OF DATA</strong>
                </strong>
              </strong>
            </strong>
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            You acknowledge that Licensor will be able to access and adjust Your
            downloaded Licensed Application content and Your personal
            information, and that Licensor's use of such material and
            information is subject to Your legal agreements with Licensor and
            Licensor's privacy policy, which can be accessed by Users can find
            Privacy Policy at the bottom of the login screen or "About Game"
            section on the settings..
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            You acknowledge that the Licensor may periodically collect and use
            technical data and related information about your device, system,
            and application software, and peripherals, offer product support,
            facilitate the software updates, and for purposes of providing other
            services to you (if any) related to the Licensed Application.
            Licensor may also use this information to improve its products or to
            provide services or technologies to you, as long as it is in a form
            that does not personally identify you.
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div id="ugc" data-custom-class="heading_1">
            <strong>
              <strong>
                <strong>
                  <strong>6. USER-GENERATED CONTRIBUTIONS</strong>
                </strong>
              </strong>
            </strong>
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            The Licensed Application does not offer users to submit or post
            content. We may provide you with the opportunity to create, submit,
            post, display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or in the Licensed Application,
            including but not limited to text, writings, video, audio,
            photographs, graphics, comments, suggestions, or personal
            information or other material (collectively, "Contributions").
            Contributions may be viewable by other users of the Licensed
            Application and through third-party websites or applications. As
            such, any Contributions you transmit may be treated in accordance
            with the Licensed Application Privacy Policy. When you create or
            make available any Contributions, you thereby represent and warrant
            that:
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            1. The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </div>
          <div data-custom-class="body_text">
            2. You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Licensed Application, and other users of the Licensed
            Application to use your Contributions in any manner contemplated by
            the Licensed Application and this License Agreement.
          </div>
          <div data-custom-class="body_text">
            3. You have the written consent, release, and/or permission of each
            and every identifiable individual person in your Contributions to
            use the name or likeness or each and every such identifiable
            individual person to enable inclusion and use of your Contributions
            in any manner contemplated by the Licensed Application and this
            License Agreement.
          </div>
          <div data-custom-class="body_text">
            4. Your Contributions are not false, inaccurate, or misleading.
          </div>
          <div data-custom-class="body_text">
            5. Your Contributions are not unsolicited or unauthorized
            advertising, promotional materials, pyramid schemes, chain letters,
            spam, mass mailings, or other forms of solicitation.
          </div>
          <div data-custom-class="body_text">
            6. Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </div>
          <div data-custom-class="body_text">
            7. Your Contributions do not ridicule, mock, disparage, intimidate,
            or abuse anyone.
          </div>
          <div data-custom-class="body_text">
            8. Your Contributions are not used to harass or threaten (in the
            legal sense of those terms) any other person and to promote violence
            against a specific person or class of people.
          </div>
          <div data-custom-class="body_text">
            9. Your Contributions do not violate any applicable law, regulation,
            or rule.
          </div>
          <div data-custom-class="body_text">
            10. Your Contributions do not violate the privacy or publicity
            rights of any third party.
          </div>
          <div data-custom-class="body_text">
            11. Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </div>
          <div data-custom-class="body_text">
            12. Your Contributions do not include any offensive comments that
            are connected to race, national origin, gender, sexual preference,
            or physical handicap.
          </div>
          <div data-custom-class="body_text">
            13. Your Contributions do not otherwise violate, or link to material
            that violates, any provision of this License Agreement, or any
            applicable law or regulation.
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            Any use of the Licensed Application in violation of the foregoing
            violates this License Agreement and may result in, among other
            things, termination or suspension of your rights to use the Licensed
            Application.
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div id="contribution" data-custom-class="heading_1">
            <strong>
              <strong>
                <strong>
                  <strong>7. CONTRIBUTION LICENSE</strong>
                </strong>
              </strong>
            </strong>
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            You agree that we may access, store, process, and use any
            information and personal data that you provide following the terms
            of the Privacy Policy and your choices (including settings).
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            By submitting suggestions of other feedback regarding the Licensed
            Application, you agree that we can use and share such feedback for
            any purpose without compensation to you.
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area in
            the Licensed Application. You are solely responsible for your
            Contributions to the Licensed Application and you expressly agree to
            exonerate us from any and all responsibility and to refrain from any
            legal action against us regarding your Contributions.
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div id="liability" data-custom-class="heading_1">
            <strong>
              <strong>
                <strong>
                  <strong>8. LIABILITY</strong>
                </strong>
              </strong>
            </strong>
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            8.1 &nbsp;Licensor's responsibility in the case of violation of
            obligations and tort shall be limited to intent and gross
            negligence. Only in case of a breach of essential contractual duties
            (cardinal obligations), Licensor shall also be liable in case of
            slight negligence. In any case, liability shall be limited to the
            foreseeable, contractually typical damages. The limitation mentioned
            above does not apply to injuries to life, limb, or health.
          </div>
          <div>&nbsp;</div>
          <div data-custom-class="body_text">
            8.2 &nbsp;Licensor takes no accountability or responsibility for any
            damages caused due to a breach of duties according to Section 2 of
            this License Agreement. To avoid data loss, You are required to make
            use of backup functions of the Licensed Application to the extent
            allowed by applicable third-party terms and conditions of use. You
            are aware that in case of alterations or manipulations of the
            Licensed Application, You will not have access to the Licensed
            Application.
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div id="warranty" data-custom-class="heading_1">
            <strong>
              <strong>
                <strong>
                  <strong>9. WARRANTY</strong>
                </strong>
              </strong>
            </strong>
          </div>
        </div>
      </div>
      <div>&nbsp;</div>
      <div data-custom-class="body_text">
        9.1 &nbsp;Licensor warrants that the Licensed Application is free of
        spyware, trojan horses, viruses, or any other malware at the time of
        Your download. Licensor warrants that the Licensed Application works as
        described in the user documentation.
      </div>
      <div>&nbsp;</div>
      <div data-custom-class="body_text">
        9.2 &nbsp;No warranty is provided for the Licensed Application that is
        not executable on the device, that has been unauthorizedly modified,
        handled inappropriately or culpably, combined or installed with
        inappropriate hardware or software, used with inappropriate accessories,
        regardless if by Yourself or by third parties, or if there are any other
        reasons outside of Bleecker Games's sphere of influence that affect the
        executability of the Licensed Application.
      </div>
      <div>&nbsp;</div>
      <div data-custom-class="body_text">
        9.3 &nbsp;You are required to inspect the Licensed Application
        immediately after installing it and notify Bleecker Games about issues
        discovered without delay by email provided in&nbsp;
        <a href="https://wordtohtml.net/#contact" data-custom-class="link">
          Contact Information
        </a>
        . The defect report will be taken into consideration and further
        investigated if it has been emailed within a period of ninety (90) days
        after discovery.
      </div>
      <div>&nbsp;</div>
      <div data-custom-class="body_text">
        9.4 &nbsp;If we confirm that the Licensed Application is defective,
        Bleecker Games reserves a choice to remedy the situation either by means
        of solving the defect or substitute delivery.
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          9.5&nbsp;&nbsp;In the event of any failure of the Licensed Application
          to conform to any applicable warranty, You may notify the Services
          Store Operator, and Your Licensed Application purchase price will be
          refunded to You. To the maximum extent permitted by applicable law,
          the Services Store Operator will have no other warranty obligation
          whatsoever with respect to the Licensed Application, and any other
          losses, claims, damages, liabilities, expenses, and costs attributable
          to any negligence to adhere to any warranty.
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          9.6&nbsp;&nbsp;If the user is an entrepreneur, any claim based on
          faults expires after a statutory period of limitation amounting to
          twelve (12) months after the Licensed Application was made available
          to the user. The statutory periods of limitation given by law apply
          for users who are consumers.
        </div>
      </div>
      <div>&nbsp; &nbsp;</div>
      <div>&nbsp;</div>
      <div>
        <div id="productclaims" data-custom-class="heading_1">
          <strong>
            <span id="productclaims">
              <strong>
                <strong>
                  <strong>
                    <strong>
                      <strong>
                        <strong>
                          <strong>10. PRODUCT CLAIMS</strong>
                        </strong>
                      </strong>
                    </strong>
                  </strong>
                </strong>
              </strong>
            </span>
          </strong>
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          Bleecker Games&nbsp;and the End-User acknowledge that Bleecker Games,
          and not the Services, is responsible for addressing any claims of the
          End-User or any third party relating to the Licensed Application or
          the End-User&rsquo;s possession and/or use of that Licensed
          Application, including, but not limited to:
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">(i) product liability claims;</div>
        &nbsp; &nbsp;
      </div>
      <div>
        <div data-custom-class="body_text">
          (ii) any claim that the Licensed Application fails to conform to any
          applicable legal or regulatory requirement; and
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          (iii) claims arising under consumer protection, privacy, or similar
          legislation, including in connection with Your Licensed
          Application&rsquo;s use of the HealthKit and HomeKit.
        </div>
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>
        <div id="compliance" data-custom-class="heading_1">
          <strong>
            <strong>
              <strong>
                <strong>
                  <strong>
                    <strong>
                      <strong>
                        <strong>
                          <strong>11. LEGAL COMPLIANCE</strong>
                        </strong>
                      </strong>
                    </strong>
                  </strong>
                </strong>
              </strong>
            </strong>
          </strong>
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          You represent and warrant that You are not located in a country that
          is subject to a US Government embargo, or that has been designated by
          the US Government as a "terrorist supporting" country; and that You
          are not listed on any US Government list of prohibited or restricted
          parties.
        </div>
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>
        <div id="contact" data-custom-class="heading_1">
          <strong>
            <strong>
              <strong>
                <strong>
                  <strong>
                    <strong>
                      <strong>
                        <strong>
                          <strong>
                            <strong>12. CONTACT INFORMATION</strong>
                          </strong>
                        </strong>
                      </strong>
                    </strong>
                  </strong>
                </strong>
              </strong>
            </strong>
          </strong>
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          For general inquiries, complaints, questions or claims concerning the
          Licensed Application, please contact:
        </div>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <div data-custom-class="body_text">Caner Kuru</div>
        <div data-custom-class="body_text">3920 Greenpoint Ave</div>
        <div data-custom-class="body_text">Queens, NY 11104</div>
        <div data-custom-class="body_text">United States</div>
        <div data-custom-class="body_text">help@bleeckergames.com</div>
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>
        <div id="termination" data-custom-class="heading_1">
          <strong>
            <strong>
              <strong>
                <strong>
                  <strong>
                    <strong>
                      <strong>
                        <strong>
                          <strong>
                            <strong>
                              <strong>13. TERMINATION</strong>
                            </strong>
                          </strong>
                        </strong>
                      </strong>
                    </strong>
                  </strong>
                </strong>
              </strong>
            </strong>
          </strong>
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          The license is valid until terminated by Bleecker Games or by You.
          Your rights under this license will terminate automatically and
          without notice from Bleecker Games if You fail to adhere to any
          term(s) of this license. Upon License termination, You shall stop all
          use of the Licensed Application, and destroy all copies, full or
          partial, of the Licensed Application.
        </div>
      </div>
      <div>
        &nbsp; &nbsp; &nbsp;&nbsp;
        <div>&nbsp;</div>
      </div>
      <div>
        <div id="thirdparty" data-custom-class="heading_1">
          <strong>
            <strong>
              <strong>
                <strong>
                  <strong>
                    <strong>
                      <strong>
                        <strong>
                          <strong>
                            <strong>
                              <strong>
                                <strong>
                                  14. THIRD-PARTY TERMS OF AGREEMENTS AND
                                  BENEFICIARY
                                </strong>
                              </strong>
                            </strong>
                          </strong>
                        </strong>
                      </strong>
                    </strong>
                  </strong>
                </strong>
              </strong>
            </strong>
          </strong>
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          Bleecker Games represents and warrants that Bleecker Games will comply
          with applicable third-party terms of agreement when using Licensed
          Application.
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          In Accordance with Section 9 of the "Instructions for Minimum Terms of
          Developer's End-User License Agreement," both Apple and Google and
          their subsidiaries shall be third-party beneficiaries of this End User
          License Agreement and &mdash; upon Your acceptance of the terms and
          conditions of this License Agreement, both Apple and Google will have
          the right (and will be deemed to have accepted the right) to enforce
          this End User License Agreement against You as a third-party
          beneficiary thereof.
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div>&nbsp;</div>
        <div id="ipr" data-custom-class="heading_1">
          <strong>
            <strong>
              <strong>
                <strong>
                  <strong>
                    <strong>
                      <strong>
                        <strong>
                          <strong>
                            <strong>
                              <strong>
                                <strong>
                                  <strong>
                                    15. INTELLECTUAL PROPERTY RIGHTS
                                  </strong>
                                </strong>
                              </strong>
                            </strong>
                          </strong>
                        </strong>
                      </strong>
                    </strong>
                  </strong>
                </strong>
              </strong>
            </strong>
          </strong>
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          Bleecker Games and the End-User acknowledge that, in the event of any
          third-party claim that the Licensed Application or the End-User's
          possession and use of that Licensed Application infringes on the third
          party's intellectual property rights, Bleecker Games, and not the
          Services, will be solely responsible for the investigation, defense,
          settlement, and discharge or any such intellectual property
          infringement claims.
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div>&nbsp;</div>
        <div id="law" data-custom-class="heading_1">
          <strong>
            <strong>
              <strong>
                <strong>
                  <strong>
                    <strong>
                      <strong>
                        <strong>
                          <strong>
                            <strong>
                              <strong>
                                <strong>
                                  <strong>
                                    <strong>16. APPLICABLE LAW</strong>
                                  </strong>
                                </strong>
                              </strong>
                            </strong>
                          </strong>
                        </strong>
                      </strong>
                    </strong>
                  </strong>
                </strong>
              </strong>
            </strong>
          </strong>
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div data-custom-class="body_text">
          This License Agreement is governed by the laws of the State of New
          York excluding its conflicts of law rules.
        </div>
      </div>
      <div>&nbsp;</div>
      <div>
        <div>&nbsp;</div>
        <div id="misc" data-custom-class="heading_1">
          <strong>
            <strong>
              <strong>
                <strong>
                  <strong>
                    <strong>
                      <strong>
                        <strong>
                          <strong>
                            <strong>
                              <strong>
                                <strong>
                                  <strong>
                                    <strong>
                                      <strong>17. MISCELLANEOUS</strong>
                                    </strong>
                                  </strong>
                                </strong>
                              </strong>
                            </strong>
                          </strong>
                        </strong>
                      </strong>
                    </strong>
                  </strong>
                </strong>
              </strong>
            </strong>
          </strong>
        </div>
        <div>&nbsp;</div>
        <div>
          <div data-custom-class="body_text">
            17.1&nbsp;&nbsp;If any of the terms of this agreement should be or
            become invalid, the validity of the remaining provisions shall not
            be affected. Invalid terms will be replaced by valid ones formulated
            in a way that will achieve the primary purpose.
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <div data-custom-class="body_text">
            17.2&nbsp;&nbsp;Collateral agreements, changes and amendments are
            only valid if laid down in writing. The preceding clause can only be
            waived in writing.
          </div>
        </div>
      </div>
    </div>
  );
};
