import React from "react";
import { SocialIcon } from "react-social-icons";
import "./SocialIcons.scss";

export const SocialIcons = () => {
  return (
    <div className="social-icons-container">
      <SocialIcon
        url="https://www.instagram.com"
        target="_blank"
        rel="noreferrer"
      />
      <SocialIcon
        url="https://www.facebook.com"
        target="_blank"
        rel="noreferrer"
      />
      <SocialIcon url="https://twitter.com" target="_blank" rel="noreferrer" />
      <SocialIcon
        url="https://www.tiktok.com"
        target="_blank"
        rel="noreferrer"
      />
    </div>
  );
};
