import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "./Home/Home";
import { PrivacyPolicy } from "./PrivacyPolicy/PrivacyPolicy";
import { TermsOfUse } from "./TermsOfUse/TermsOfUse";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-of-use",
    element: <TermsOfUse />,
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
